import { Flex, Stack } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import NoticeModal from 'components/ConnectAccount/NoticeModal'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import moment from 'moment'
import useAuth from '../../hooks/useAuth'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectNotice from 'components/ConnectNotice'
import { useModal } from '@pancakeswap/uikit'

const Wrapper = styled.div`
  width: 100%;
  height: 1180px;
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.homeBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1024px) {
    background-image: url(${images.homeMobileBG});
    background-position: top;
    background-size: cover;
  }
  @media (max-width: 575px) {
    background-image: url(${images.homeMobileBG});
    background-position: center;
    background-size: cover;
    height: 666px;
  }
`
const Container = styled.div`
  padding: 250px 205px 50px 205px;
  @media (max-width: 1919px) {
    padding: 253px 180px 200px 180px;
  }
  @media (max-width: 1420px) {
    display: flex;
    justify-content: center;
    padding: 300px 180px 200px 180px;
  }
  @media (max-width: 1024px) {
    padding: 500px 100px 60px;
  }
  @media (max-width: 768px) {
    padding: 400px 100px 60px;
  }
  @media (max-width: 575px) {
    padding: 180px 20px 45px 20px;
  }
`

const StyleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  text-align: center;
  padding-top: 30px;
  padding-left: 110px;
  padding-right: 110px;
`

const PageAccount: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive()
  const { connetSocial } = useAuth()
  const { account } = useActiveWeb3React()
  const [openModal, closeModal] = useModal(<ConnectNotice onDismiss={() => closeModal()} />, false)

  useEffect(() => {
    const timeToken = parseInt(localStorage.getItem('ExpireDay'))
    const timeNow = moment().unix()
    console.log(timeNow)
    if (timeToken) {
      if (timeNow > timeToken) {
        localStorage.removeItem('TokenGame')
        localStorage.removeItem('ExpireDay')
      }
    } else if (!timeToken && account) {
      if (account) {
        connetSocial(account)
      }
    }
  }, [account])

  return (
    <>
      <Wrapper>
        <Container>
          <div
            style={{
              backgroundImage: `url(${images.bgPageAcc})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '1636px',
              height: '869px',
            }}
          >
            <StyleContainer>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      backgroundImage: `url(${images.inGame})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      width: '284px',
                      height: '50px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '32px',
                        fontWeight: '600',
                        height: '51px',
                        lineHeight: '51.23px',
                      }}
                    >
                      In Game
                    </p>
                  </div>
                </div>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div
                    style={{
                      backgroundImage: `url(${images.equipment})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      width: '211px',
                      height: '47px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '24px',
                        fontWeight: '600',
                        height: '38px',
                        lineHeight: '38.42px',
                        backgroundImage: 'linear-gradient(180deg, #D8362B 0%, #F66F15 100%)',
                        WebkitBackgroundClip: 'text',
                        backgroundClip: 'text',
                        color: 'transparent',
                      }}
                    >
                      I- equipments:
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    backgroundImage: `url(${images.bgEquipment})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: '597.85px',
                    height: '398.57px',
                  }}
                >
                  <div style={{ paddingTop: '18px', paddingLeft: '10px' }}>
                    <div
                      style={{
                        backgroundImage: `url(${images.bgCharater})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        width: '65.9px',
                        height: '222.08px',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '6px',
                          fontWeight: '600',
                          height: '9.81px',
                          lineHeight: '9.61px',
                          paddingTop: '30px',
                        }}
                      >
                        Character
                      </p>
                      <div style={{ paddingTop: '60px', paddingLeft: '13px' }}>
                        <div
                          style={{
                            backgroundImage: `url(${images.btnEquip})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '6px',
                              fontWeight: '600',
                              height: '10px',
                              lineHeight: '9.61px',
                              paddingTop: '15px',
                            }}
                          >
                            Equipments
                          </p>
                        </div>
                      </div>
                      <div style={{ paddingTop: '5px', paddingLeft: '13px' }}>
                        <div
                          style={{
                            backgroundImage: `url(${images.btnCharacter})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '6px',
                              fontWeight: '600',
                              height: '10px',
                              lineHeight: '9.61px',
                              paddingTop: '15px',
                            }}
                          >
                            Properties
                          </p>
                        </div>
                      </div>
                      <div style={{ paddingTop: '2px', paddingLeft: '13px' }}>
                        <div
                          style={{
                            backgroundImage: `url(${images.btnCharacter})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '6px',
                              fontWeight: '600',
                              height: '10px',
                              lineHeight: '9.61px',
                              paddingTop: '15px',
                            }}
                          >
                            Glory
                          </p>
                        </div>
                      </div>
                      <div style={{ paddingTop: '2px', paddingLeft: '13px' }}>
                        <div
                          style={{
                            backgroundImage: `url(${images.btnCharacter})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '6px',
                              fontWeight: '600',
                              height: '10px',
                              lineHeight: '9.61px',
                              paddingTop: '15px',
                            }}
                          >
                            Title
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>
                  <div></div>
                </div>
              </div>
              <div>222</div>
            </StyleContainer>
          </div>
        </Container>
      </Wrapper>
    </>
  )
}

export default PageAccount
