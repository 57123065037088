import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { connectorLocalStorageKey, useModal, useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { ConnectorNames } from 'config/wallet'
import { useConnect, useDisconnect, useNetwork, ConnectorNotFoundError, UserRejectedRequestError } from 'wagmi'
import { clearUserStates } from '../utils/clearUserStates'
import { useActiveChainId } from './useActiveChainId'
import { useSessionChainId } from './useSessionChainId'
import { ethers } from 'ethers'
import moment from 'moment'
import useActiveWeb3React from './useActiveWeb3React'
import NoticeModal from 'components/ConnectAccount/NoticeModal'

const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { toastError } = useToast()
  const { chainId } = useActiveChainId()
  const { account } = useActiveWeb3React()
  const [, setSessionChainId] = useSessionChainId()
  const [openModal, closeModal] = useModal(<NoticeModal onDismiss={() => closeModal()} />, false)
  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chainId', connected.chain.id)
          setSessionChainId(connected.chain.id)
        }
        await getSignatureMessage(connected.account)
      } catch (error) {
        console.error(error)
        window?.localStorage?.removeItem(connectorLocalStorageKey)
        if (error instanceof ConnectorNotFoundError) {
          toastError(t('Provider Error'))
          return
        }
        if (error instanceof UserRejectedRequestError) {
          return
        }
        if (error instanceof Error) {
          toastError(error.message, t('Please authorize to access your account'))
        }
      }
    },
    [connectors, connectAsync, chainId, setSessionChainId, toastError, t],
  )

  const getSignatureMessage = async (account) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum as any)
      const signer = provider.getSigner()
      const currentTimeInMilliseconds = moment().valueOf()
      const messageToSign = 'Crypto Manhua game wants to sign in your wallet: ' + currentTimeInMilliseconds
      const signature = await signer.signMessage(messageToSign)
      const api = process.env.NEXT_PUBLIC_DEFAULT_API
      const apiUrl = `${api}/wallet_connect/auth`
      const payload = {
        address: account,
        signature: signature,
        nonce: currentTimeInMilliseconds,
        deviceToken: '',
      }
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
          return response.json()
        })
        .then((data) => {
          window?.localStorage?.setItem('user_social', JSON.stringify(data.data))
          const gameToken = data.data.token
          const gameLink = `${gameToken}`
          localStorage.setItem('TokenGame', gameLink)
          localStorage.setItem('ExpireDay', moment().add(1, 'days').unix().toString())
          openModal()
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } catch (error) {
      console.error('Error signing message:', error)
    }
  }

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
      window?.localStorage?.setItem('user_social', '')
      localStorage.removeItem('TokenGame')
      localStorage.removeItem('ExpireDay')
    } catch (error) {
      console.error(error)
    } finally {
      clearUserStates(dispatch, { chainId: chain?.id, isDeactive: true })
    }
  }, [disconnectAsync, dispatch, chain?.id])

  const connetSocial = useCallback(
    async (account) => {
      try {
        await getSignatureMessage(account)
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          toastError(error.message, t('Please try again'))
        }
      }
    },
    [account],
  )

  return { login, logout, connetSocial }
}

export default useAuth
