import { Flex, Stack } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import NoticeModal from 'components/ConnectAccount/NoticeModal'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import moment from 'moment'
import useAuth from '../../hooks/useAuth'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectNotice from 'components/ConnectNotice'
import { useModal } from '@pancakeswap/uikit'

const Wrapper = styled.div`
  width: 100%;
  height: 1180px;
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.homeBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1024px) {
    background-image: url(${images.homeMobileBG});
    background-position: top;
    background-size: cover;
  }
  @media (max-width: 575px) {
    background-image: url(${images.homeMobileBG});
    background-position: center;
    background-size: cover;
    height: 666px;
  }
`
const Container = styled.div`
  padding: 253px 180px 200px 260px;
  @media (max-width: 1919px) {
    padding: 253px 180px 200px 180px;
  }
  @media (max-width: 1420px) {
    display: flex;
    justify-content: center;
    padding: 300px 180px 200px 180px;
  }
  @media (max-width: 1024px) {
    padding: 500px 100px 60px;
  }
  @media (max-width: 768px) {
    padding: 400px 100px 60px;
  }
  @media (max-width: 575px) {
    padding: 180px 20px 45px 20px;
  }
`

const StyleButton1 = styled.div`
  background-image: url(${images.bttImport});
  background-position: center;
  background-size: cover;
  width: 243.44px;
  height: 61.49px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 235ms ease-in-out;
  &:hover {
    background-image: url(${images.importHover});
  }
`

const StyleButton2 = styled.div`
  background-image: url(${images.bttImport});
  background-position: center;
  background-size: cover;
  width: 192px;
  height: 49px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 235ms ease-in-out;
  &:hover {
    background-image: url(${images.importHover});
  }
`

const StyleDownLoad = styled.div`
  background-image: url(${images.bgDL});
  background-position: center;
  background-size: cover;
  width: 196.66px;
  height: 62.64px;
  cursor: pointer;
  transition: all 235ms ease-in-out;
  color: #17e0ff;
  &:hover {
    background-image: url(${images.bgDLHover});
    color: white;
  }
`
const StyleDownLoadMB = styled.div`
  background-image: url(${images.bgDL});
  background-position: center;
  background-size: cover;
  width: 242px;
  height: 82px;
  margin-right: 80px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 235ms ease-in-out;

  @media screen and (max-width: 768px) {
    width: 121px;
    height: 41px;
    margin-right: 40px;
    margin-bottom: 5px;
  }
`

const StyleImportMB = styled.div`
  background-image: url(${images.importMB});
  background-position: center;
  background-size: cover;
  width: 234px;
  height: 76.04px;
  cursor: pointer;
  transition: all 235ms ease-in-out;

  @media screen and (max-width: 768px) {
    width: 117px;
    height: 38.02px;
  }
`

const dataSocial = [
  {
    img: images.facebook,
    link: '',
  },
  {
    img: images.community,
    link: '',
  },
  {
    img: images.youtube,
    link: '',
  },
  {
    img: images.x,
    link: '',
  },
  {
    img: images.telegram,
    link: '',
  },
]

const dataSocialMobile = [
  {
    img: images.fbMobile,
    link: '',
  },
  {
    img: images.comunityMobile,
    link: '',
  },
  {
    img: images.youtubeMobile,
    link: '',
  },
  {
    img: images.xMobile,
    link: '',
  },
  {
    img: images.telegramMobile,
    link: '',
  },
]

const buttonDownload = [
  {
    icon: images.app,
    text: 'APPSTORE',
    link: '',
  },
  {
    icon: images.chplay,
    text: 'CH PLAY',
    link: 'https://play.google.com/store/apps/details?id=com.tdsolution.cryptomanhua',
  },
  {
    icon: images.pc,
    text: 'PC',
    link: '',
  },
]

const HomePage: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive()
  const { connetSocial } = useAuth()
  const { account } = useActiveWeb3React()
  const [openModal, closeModal] = useModal(<ConnectNotice onDismiss={() => closeModal()} />, false)

  useEffect(() => {
    const timeToken = parseInt(localStorage.getItem('ExpireDay'))
    const timeNow = moment().unix()
    console.log(timeNow)
    if (timeToken) {
      if (timeNow > timeToken) {
        localStorage.removeItem('TokenGame')
        localStorage.removeItem('ExpireDay')
      }
    }
    // } else if (!timeToken && account) {
    //   if (account) {
    //     connetSocial(account)
    //   }
    // }
  }, [account])

  return (
    <>
      <Wrapper>
        <Container>
          <Flex justifyContent="space-between" direction={isMobileOrTablet ? 'column' : 'row'} gap="30px">
            <Stack
              display={window.screen.width < 1420 ? 'none' : 'flex'}
              position="relative"
              justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
              alignItems={isMobileOrTablet ? 'center' : 'flex-start'}
            >
              <img
                style={{
                  width: '301px',
                  height: '576px',
                }}
                src={images.bannerHome1}
              />
              <Stack
                position="absolute"
                justifyContent={'center'}
                alignItems={'center'}
                left={isMobileOrTablet ? '37.5%' : '54px'}
                top={isMobileOrTablet ? '13%' : isTabletOrLaptop ? '5%' : '7%'}
              >
                <img width={isTabletOrLaptop ? '140px' : '145px'} style={{ marginBottom: '5px' }} src={images.avtB1} />
                {buttonDownload.map((item) => (
                  <a href={item.link} target="blank" rel="no-referrer">
                    <StyleDownLoad onClick={openModal}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '153px',
                          height: '36px',
                          marginLeft: '27px',
                          padding: '35px 0',
                        }}
                      >
                        <img src={item.icon} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                        <div style={{ width: '114px', height: '36px' }}>
                          <p
                            style={{
                              fontSize: '14px',
                              fontWeight: '400',
                              height: '18px',
                              lineHeight: '18px',
                              width: '114px',
                            }}
                          >
                            DOWNLOAD ON
                          </p>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              height: '18px',
                              lineHeight: '18px',
                              width: '114px',
                            }}
                          >
                            {item.text}
                          </p>
                        </div>
                      </div>
                    </StyleDownLoad>
                  </a>
                ))}
                <StyleButton2 onClick={openModal}>
                  <p
                    style={{
                      color: '#FFFFFF',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      marginLeft: '58px',
                      marginTop: '17px',
                      lineHeight: '18px',
                    }}
                  >
                    IMPORT
                  </p>
                </StyleButton2>
              </Stack>
            </Stack>
            <Stack justifyContent="center" alignItems="center" gap={isMobile ? '0px' : '0.5rem'}>
              <Flex position="relative" display={window.screen.width < 1420 ? 'none' : 'flex'}>
                <img
                  style={{
                    width: '740px',
                    height: '404px',
                  }}
                  src={images.bannerHome2}
                />
                <Stack position="absolute" top={isTabletOrLaptop ? '24%' : '77px'} left="15%">
                  {buttonDownload.map((item) => (
                    <a href={item.link} target="blank" rel="no-referrer">
                      <StyleDownLoad onClick={openModal}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '153px',
                            height: '36px',
                            marginLeft: '27px',
                            padding: '35px 0',
                          }}
                        >
                          <img src={item.icon} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                          <div style={{ width: '114px', height: '36px' }}>
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                height: '18px',
                                lineHeight: '18px',
                                width: '114px',
                              }}
                            >
                              DOWNLOAD ON
                            </p>
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                height: '18px',
                                lineHeight: '18px',
                                width: '114px',
                              }}
                            >
                              {item.text}
                            </p>
                          </div>
                        </div>
                      </StyleDownLoad>
                    </a>
                  ))}
                </Stack>
                <Stack alignItems="center" position="absolute" top={isTabletOrLaptop ? '24%' : '25%'} left="55%">
                  <img width="165px" src={images.chest} />
                  <StyleButton1 onClick={openModal}>
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        marginLeft: '83px',
                        marginTop: '21px',
                        lineHeight: '18px',
                      }}
                    >
                      IMPORT
                    </p>
                  </StyleButton1>
                </Stack>
              </Flex>
              <Flex
                position="relative"
                display={window.screen.width < 1420 ? 'flex' : 'none'}
                justifyContent="center"
                alignItems="center"
                w={window.screen.width <= 767 ? '347px' : '694px'}
                height={window.screen.width <= 767 ? '61px' : '122px'}
                style={{
                  backgroundImage: `url(${images.navHomeMobile})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginBottom: window.screen.width <= 575 && window.screen.width >= 425 ? '70px' : '',
                }}
              >
                <Flex justifyContent="center" alignItems="center">
                  <StyleDownLoadMB onClick={openModal}>
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                        fontSize: window.screen.width <= 767 ? '14px' : '28px',
                        marginLeft: window.screen.width <= 767 ? '20px' : '40px',
                        marginTop: window.screen.width <= 767 ? '13px' : '26px',
                        lineHeight: window.screen.width <= 767 ? '18px' : '36px',
                      }}
                    >
                      DOWNLOAD
                    </p>
                  </StyleDownLoadMB>{' '}
                  <img
                    style={{
                      width: window.screen.width <= 767 ? '118.6px' : '237.2px',
                      position: 'absolute',
                      left: window.screen.width <= 767 ? '115px' : '230px',
                      zIndex: 1,
                      height: window.screen.width <= 767 ? '90.43px' : '180.86px',
                    }}
                    src={images.light}
                  />
                  <img
                    style={{
                      position: 'absolute',
                      left: window.screen.width <= 767 ? '143px' : '286px',
                      zIndex: 1,
                      width: window.screen.width <= 767 ? '67px' : '134px',
                      height: window.screen.width <= 767 ? '50px' : '100px',
                    }}
                    src={images.chest}
                  />
                  <StyleImportMB onClick={openModal}>
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                        fontSize: window.screen.width <= 767 ? '14px' : '28px',
                        marginLeft: window.screen.width <= 767 ? '30px' : '60px',
                        marginTop: window.screen.width <= 767 ? '9px' : '18px',
                        lineHeight: window.screen.width <= 767 ? '18px' : '36px',
                      }}
                    >
                      IMPORT
                    </p>
                  </StyleImportMB>{' '}
                </Flex>
              </Flex>
              <Flex
                mt={window.screen.width <= 575 ? '27px' : '40px'}
                flexWrap="wrap"
                w={window.screen.width <= 575 ? '100%' : '740px'}
                justifyContent="center"
              >
                {isMobile
                  ? dataSocialMobile.map((item) => (
                      <Stack
                        mt="8px"
                        cursor="pointer"
                        transition="all 235ms ease-in-out"
                        _hover={{
                          transform: 'translate3d(0, 2px, 0)',
                        }}
                      >
                        <img
                          width={window.screen.width <= 767 ? '94px' : '244px'}
                          style={{
                            marginRight: window.screen.width <= 767 ? '4px' : '',
                            marginBottom: window.screen.width <= 575 ? '24px' : '',
                          }}
                          src={item.img}
                        />
                      </Stack>
                    ))
                  : dataSocial.map((item) => (
                      <Stack
                        mt="8px"
                        cursor="pointer"
                        transition="all 235ms ease-in-out"
                        _hover={{
                          transform: 'translate3d(0, 2px, 0)',
                        }}
                      >
                        <img
                          width={window.screen.width <= 575 ? '94px' : '244px'}
                          style={{
                            marginRight: window.screen.width <= 575 ? '4px' : '',
                            marginBottom: window.screen.width <= 575 ? '24px' : '',
                          }}
                          src={item.img}
                        />
                      </Stack>
                    ))}
              </Flex>
              <Stack
                mt={window.screen.width <= 425 ? '' : '70px'}
                marginLeft={window.screen.width <= 1420 ? '' : '40px'}
                justifyContent="center"
                alignItems="center"
              >
                <img width={window.screen.width <= 575 ? '347px' : '460px'} src={images.term} />
              </Stack>
            </Stack>
          </Flex>
        </Container>
      </Wrapper>
    </>
  )
}

export default HomePage
