import React, { useState } from 'react'
import { ModalBody, ModalCloseButton } from '../../packages/uikit/src/widgets/Modal/styles'
import { Stack } from '@chakra-ui/react'
import styled from 'styled-components'
import { Form, type FormProps, Input } from 'antd'
import images from 'constant/PICTURE/images'
import { MotionBox } from '@pancakeswap/uikit'

export const ModalHeader1 = styled.div<{ headerBackground?: string }>`
  align-items: center;
  display: flex;
  border-radius: 24px;
  background: #fff;
  right: -8px;
  top: 15px;
  position: absolute;
  ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ headerBackground }) => headerBackground || 'transparent'};
  }
`

export const ModalContainer = styled(MotionBox)<{ $minWidth: string }>`
  background-image: url(${images.bannerModal});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  top: 300px;
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: 300px;
  padding 32px;
  

  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    position: auto;
    bottom: auto;
    max-width: 100%;
    max-height: 100vh;
  }
`

const StyledNoButton = styled.a`
  height: 34px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/NoButton.svg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #00f0ff;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
`

const StyledYesButton = styled.a`
  width: 110px;
  height: 34px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/YesButton.svg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #00f0ff;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
`

const ConnectNotice = ({ onDismiss }: { onDismiss: () => void }) => {
  const [displayModal, setDisplayModal] = useState('flex')
  const [layout, setLayout] = useState(1)

  // ConnectWalletModal

  type FieldType = {
    username?: string
    password?: string
    remember?: string
  }

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    // console.log('Success:', values)
  }

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    // console.log('Failed:', errorInfo)
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginRight: '50px' }}>
        <ModalContainer $minWidth="320px">
          <ModalHeader1>
            <ModalCloseButton onDismiss={onDismiss} />
          </ModalHeader1>
          <ModalBody style={{ marginTop: '100px' }}>
            <p style={{ color: 'white' }}>Please connect your wallet!</p>
            <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <StyledNoButton
                onClick={() => {
                  onDismiss()
                }}
                style={{ width: '110px' }}
              >
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    width: '17px',
                    height: '19px',
                    color: 'white',
                    lineHeight: '19.21px',
                  }}
                >
                  NO
                </p>
              </StyledNoButton>
              <StyledYesButton style={{ width: '110px' }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    width: '17px',
                    height: '19px',
                    color: 'white',
                    lineHeight: '19.21px',
                  }}
                >
                  YES
                </p>
              </StyledYesButton>
            </Stack>
          </ModalBody>
        </ModalContainer>
      </div>
    </div>
  )
}

export default ConnectNotice
