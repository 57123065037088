const images = {
  homeBG: '/images/crypto/backgroundHome.png',
  connecting: '/images/crypto/connecting.png',
  homeMobileBG: '/images/crypto/bgHomeMobile.png',
  navBG: '/images/crypto/navBg.png',
  bannerHome1: '/images/crypto/bannerNew.png',
  bannerHome2: '/images/crypto/banner2.png',
  chest: '/images/crypto/chest.png',
  community: '/images/crypto/community.png',
  youtube: '/images/crypto/youtube.png',
  facebook: '/images/crypto/facebook.png',
  x: '/images/crypto/x.png',
  telegram: '/images/crypto/telegram.png',
  bttApp: '/images/crypto/downAppstore.png',
  bttCH: '/images/crypto/downloadCH.png',
  bttPC: '/images/crypto/downloadPC.png',
  bttImport: '/images/crypto/bttImport.svg',
  iconApp: '/images/crypto/app.svg',
  iconCH: '/images/crypto/ch.svg',
  iconPC: '/images/crypto/pc.svg',
  avtB1: '/images/crypto/avtBanner1.png',
  term: '/images/crypto/social.png',
  navHomeMobile: '/images/crypto/navHomeMobile.png',
  download: '/images/crypto/download.png',
  importMobile: '/images/crypto/bttImportMobile.png',
  light: '/images/crypto/light.png',
  thumbnail: '/images/DarkKnight/Thumbnail.png',
  bannerModal: '/images/crypto/backgroundConnectWallet.png',
  buttonModal: '/images/crypto/buttonModal.png',
  buttonHide: '/images/crypto/buttonHide.png',
  homeMobile: '/images/crypto/homeMobile.svg',
  fbMobile: '/images/crypto/facebookMobile.png',
  xMobile: '/images/crypto/xMobile.png',
  youtubeMobile: '/images/crypto/youtubeMobile.png',
  telegramMobile: '/images/crypto/telegramMobile.png',
  comunityMobile: '/images/crypto/comunityMobile.png',
  exit: '/images/crypto/exit1.svg',
  NoButton: '/images/crypto/NoButton.svg',
  YesButton: '/images/crypto/YesButton.svg',
  bgLogin: '/images/crypto/bgLogin.svg',
  bgLogin2: '/images/crypto/bgLogin2.svg',
  buttonSuccess: '/images/crypto/buttonSuccess.svg',
  dropDownButton: '/images/crypto/dropDownButton.svg',
  playNow: '/images/crypto/playNow.svg',
  importHover: '/images/crypto/importHover.png',
  app: '/images/crypto/app.png',
  chplay: '/images/crypto/chplay.png',
  pc: '/images/crypto/pc.png',
  bgDL: '/images/crypto/bgDownLoad.png',
  bgDLHover: '/images/crypto/bgDLHover.png',
  importMB: '/images/crypto/importMB.png',
  bgPageAcc: '/images/crypto/bgPageAccount.png',
  inGame: '/images/crypto/inGame.png',
  equipment: '/images/crypto/equipment.png',
  bgEquipment: '/images/crypto/bgEquipment.png',
  bgCharater: '/images/crypto/bgCharater.png',
  btnEquip: '/images/crypto/buttonEquip.png',
  btnCharacter: '/images/crypto/btnCharacter.png',
}

export default images
