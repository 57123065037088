import APP_ROUTES from 'constant/APP_ROUTES'
import { Route, Switch } from 'react-router-dom'
import HomePage from 'views/HomePage1'
import PageAccount from 'views/PageAccount'
import NotFound from 'views/NotFound'

export const NewNav = () => {
  return (
    <>
      <div>
        <Switch>
          <Route exact path={APP_ROUTES.home}>
            <HomePage />
          </Route>

          <Route path={APP_ROUTES.account}>
            <PageAccount />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </>
  )
}
