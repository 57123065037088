import React, { useState } from 'react'
import { ModalBody, ModalCloseButton } from '../../../packages/uikit/src/widgets/Modal/styles'
// import { Overlay } from './../../../packages/uikit/src/components/Overlay/Overlay'
import { Stack } from '@chakra-ui/react'
import styled from 'styled-components'
import { Form, type FormProps, Input } from 'antd'
import images from 'constant/PICTURE/images'
import { MotionBox } from '@pancakeswap/uikit'
import useModal from 'antd/lib/modal/useModal'

export const ModalHeader1 = styled.div<{ headerBackground?: string }>`
  align-items: center;
  display: flex;
  border-radius: 24px;
  background: #fff;
  right: -7px;
  top: -5px;
  position: absolute;
  ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ headerBackground }) => headerBackground || 'transparent'};
  }
`

export const ModalHeader2 = styled.div<{ headerBackground?: string }>`
  align-items: center;
  display: flex;
  border-radius: 24px;
  background: #fff;
  right: 6px;
  top: -24px;
  position: absolute;
  ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ headerBackground }) => headerBackground || 'transparent'};
  }
`

export const ModalHeader3 = styled.div<{ headerBackground?: string }>`
  align-items: center;
  display: flex;
  border-radius: 24px;
  background: #fff;
  right: -7px;
  top: 2px;
  position: absolute;
  ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ headerBackground }) => headerBackground || 'transparent'};
  }
`

export const ModalContainer = styled(MotionBox)<{ $minWidth: string }>`
  background-image: url(${images.bannerModal});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  top: 300px;
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: 300px;
  padding 32px;
  

  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    position: auto;
    bottom: auto;
    max-width: 100%;
    max-height: 100vh;
  }
`

const StyledNoButton = styled.a`
  height: 34px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/NoButton.svg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #00f0ff;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
`

const StyledYesButton = styled.a`
  width: 110px;
  height: 34px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/YesButton.svg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #00f0ff;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
`

const StyledLogin = styled.div`
  height: 37px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/bgLogin.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
`

const StyledLogin2 = styled.div`
  height: 31px;
  margin-top: 3px;
  margin-left: 3px;
  cursor: pointer;
  background-image: url('/images/crypto/bgLogin2.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
`

const NoticeModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const [displayModal, setDisplayModal] = useState('flex')
  const [layout, setLayout] = useState(1)

  type FieldType = {
    username?: string
    password?: string
    remember?: string
  }

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values)
  }

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {layout === 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginRight: '50px' }}>
          <ModalContainer $minWidth="320px">
            <ModalHeader1 style={{ marginRight: '7px' }}>
              <ModalCloseButton onDismiss={onDismiss} />
            </ModalHeader1>
            <ModalBody style={{ marginTop: '100px' }}>
              <p style={{ color: 'white' }}>Please link your wallet to your game account</p>
              <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <StyledNoButton
                  onClick={() => {
                    onDismiss()
                  }}
                  style={{ width: '110px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '17px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    NO
                  </p>
                </StyledNoButton>
                <StyledYesButton
                  onClick={() => {
                    setLayout(2)
                  }}
                  style={{ width: '110px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '17px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    YES
                  </p>
                </StyledYesButton>
              </Stack>
            </ModalBody>
          </ModalContainer>
        </div>
      )}
      {layout === 2 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginRight: '50px' }}>
          <ModalContainer $minWidth="497px">
            <ModalHeader2 style={{ marginTop: '2px' }}>
              <ModalCloseButton onDismiss={onDismiss} />
            </ModalHeader2>
            <ModalBody style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StyledLogin style={{ width: '196px' }}>
                  <StyledLogin2 style={{ width: '94px' }} />
                </StyledLogin>
              </div>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item<FieldType>
                  label="Username"
                  name="username"
                  style={{ margin: '5px 0', fontSize: '12px', lineHeight: '19.21px', width: '228px', height: '59px' }}
                  rules={[{ required: true, message: 'Please enter a valid Username.' }]}
                >
                  <Input style={{ backgroundColor: '#CFCFCF', width: '228px', height: '39px', color: 'black' }} />
                </Form.Item>

                <Form.Item<FieldType>
                  label="Password"
                  name="password"
                  style={{ margin: '5px 0', fontSize: '12px', lineHeight: '19.21px', width: '228px', height: '59px' }}
                  rules={[{ required: true, message: 'The password fields is required.' }]}
                >
                  <Input style={{ backgroundColor: '#CFCFCF', width: '228px', height: '39px', color: 'black' }} />
                </Form.Item>
              </Form>
              <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <StyledNoButton
                  onClick={() => {
                    onDismiss()
                  }}
                  style={{ width: '110px', marginTop: '10px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '44px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    CANCEL
                  </p>
                </StyledNoButton>
                <StyledYesButton
                  onClick={() => {
                    setLayout(3)
                  }}
                  style={{ width: '110px', marginTop: '10px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '38px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    LOG IN
                  </p>
                </StyledYesButton>
              </Stack>
            </ModalBody>
          </ModalContainer>
        </div>
      )}
      {layout === 3 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginRight: '50px' }}>
          <ModalContainer $minWidth="320px">
            <ModalHeader3 style={{ marginRight: '8px' }}>
              <ModalCloseButton onDismiss={onDismiss} />
            </ModalHeader3>
            <ModalBody style={{ marginTop: '80px' }}>
              <p
                style={{
                  color: 'white',
                  width: '296px',
                  textAlign: 'left',
                  fontSize: '12px',
                  lineHeight: '19.21px',
                  fontWeight: '500',
                }}
              >
                Are you sure you have linked ID wallet <span style={{ color: '#0784C3' }}>0x...E32C</span> TO{' '}
                <span style={{ color: '#D8362B' }}>username</span>?
              </p>
              <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <StyledNoButton
                  onClick={() => {
                    onDismiss()
                  }}
                  style={{ width: '110px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '17px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    NO
                  </p>
                </StyledNoButton>
                <StyledYesButton
                  onClick={() => {
                    setLayout(4)
                  }}
                  style={{ width: '110px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '17px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    YES
                  </p>
                </StyledYesButton>
              </Stack>
            </ModalBody>
          </ModalContainer>
        </div>
      )}
      {layout === 4 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginRight: '50px' }}>
          <ModalContainer $minWidth="320px">
            <ModalHeader3 style={{ marginRight: '8px' }}>
              <ModalCloseButton onDismiss={onDismiss} />
            </ModalHeader3>
            <ModalBody style={{ marginTop: '50px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <img src={images.buttonSuccess} alt="" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white', marginBottom: '10px', fontSize: '12px' }}>SUCCESS</p>
                <p
                  style={{
                    color: 'white',
                    width: '296px',
                    fontSize: '12px',
                    lineHeight: '19.21px',
                    fontWeight: '400',
                  }}
                >
                  Congratulations on your successful linked!
                </p>
              </div>

              <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <StyledYesButton
                  onClick={() => {
                    onDismiss()
                  }}
                  style={{ width: '110px' }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      width: '57px',
                      height: '19px',
                      color: 'white',
                      lineHeight: '19.21px',
                    }}
                  >
                    CONTINUE
                  </p>
                </StyledYesButton>
              </Stack>
            </ModalBody>
          </ModalContainer>
        </div>
      )}
    </div>
  )
}

export default NoticeModal
