import { Box, Logo, useMatchBreakpoints } from '@pancakeswap/uikit'
import throttle from 'lodash/throttle'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

import { useTranslation } from '@pancakeswap/localization'
import { MENU_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { usePhishingBannerManager } from 'state/user/hooks'
import styled from 'styled-components'
import BottomNavV2 from './BottomNavV2'
import UserMenuV2 from './UserMenu/UserMenuV2'
import { NewNav } from './config/configV2'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'

import BottomNav from '@pancakeswap/uikit/src/components/BottomNav'
import { NewFooter } from 'components/NewFooter'
import Arrow from 'components/Svg/Arrow'
import APP_ROUTES from 'constant/APP_ROUTES'
import images from 'constant/PICTURE/images'
import { Stack } from '@chakra-ui/react'
import { Modal } from 'antd'
import useModal from 'antd/lib/modal/useModal'

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  //   min-height: 100vh;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 0;
  }
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  width: 100%;
`
const Wrapper = styled.div`
  width: 100%;
  background-color: #0f0d0d;
  overflow: hidden;
`
const Container = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: #0f0d0d;
`
const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: 82px;
  left: -130px;
  right: 0;
  padding: 20px 30px;
  margin: 0px auto;
  height: 90px;
  background-image: url(${images.navBG});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  // max-width: 1578px;

  @media screen and (max-width: 1919px) {
    left: -10px;
    width: 80%;
    gap: 0px;
  }
  @media screen and (max-width: 1200px) {
    padding: 0;
    gap: 10px;
  }

  @media screen and (max-width: 1024px) {
    background: #2a120a80;
    top: 0;
    left: 0;
    height: 100px;
    background-image: none;
    width: 100%;
    padding: 0 32px;
  }
  // @media screen and (max-width: 900px) {
  //   background-image: none;
  //   background: #2a120a80;
  //   padding: 40px;
  //   top: 0;
  // }
  @media screen and (max-width: 576px) {
    background: #2a120a80;
    top: 0;
    left: 0;
    height: 63px;
    background-image: none;
    width: 100%;
  }
`
const StyledMenuItem = styled.div`
  position: relative;
  color: var(--white-white, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  /* Text lg/regular */
  font-family: Anek Bangla;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  max-width: 250px;
  height: 50px;

  &:hover {
    border-radius: var(--border-radius-lg, 8px);

    .dropdown {
      display: flex;
    }
  }
  a:hover {
    color: #fff;
  }
  .dropdown {
    display: none;
  }
  @media screen and (max-width: 1300px) {
    padding: 0 10px;
  }
  .active {
    color: rgb(255, 211, 42);
  }
`
const StyledListItem = styled.div`
  display: flex;
  margin-left: 190px;
  gap: 10px;
  .active {
    border-radius: var(--border-radius-lg, 8px);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
  @media screen and (max-width: 1919px) {
    margin-left: 30px;
    gap: 0;
  }
  @media screen and (max-width: 1300px) {
    gap: 0;
  }
`
const DropdownMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #434a57;
  width: 300px;
  top: 70px;
  padding: 40px;
  border-radius: 20px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
`
const StyledDropdownMenu = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 250px;
  &:hover {
    color: rgb(255, 211, 42);

    border-radius: var(--border-radius-lg, 8px);
    background: #3d434e;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`
const StyledItemNav = styled.div`
  color: var(--white-white, #fff);
  display: flex;
  align-items: center;
  padding: 0px 16px;
  /* Text lg/regular */
  font-family: Anek Bangla;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  width: 130px;
  height: 40px;

  &:hover {
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`
const NavDropdownMenu = styled.div`
  display: none;
  max-width: 860px;
  width: 100%;
  gap: 30px;
  margin: 60px auto 0 auto;
  justify-content: center;
  .active {
    padding: 20px;
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`
const TextNav = styled.text`
  color: #fff;
  font-family: Anek Bangla;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.63px;

  &:hover {
    color: rgb(255, 211, 42);
  }
`

const MenuV2 = () => {
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const router = useRouter()
  const [showMenu, setShowMenu] = useState(true)
  const [classActive, setClassActive] = useState('')
  const [indexActive, setIndexActive] = useState(0)
  // const [indexDropdown , setIndexDropdown] = useState(0)
  // const indexDropdown = useRef(0)
  const [isActive, setisActive] = useState(0)
  const [notFound, setNotFound] = useState(false)
  // console.log(indexDropdown.current);
  const { pathname } = useRouter()
  const { isDark, setTheme } = useTheme()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const banner = showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  const totalTopMenuHeight = banner ? MENU_HEIGHT : MENU_HEIGHT
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })

  const menuItems = useMenuItems()
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const data = [
    {
      img: '',
      label: '',
      link: APP_ROUTES.home,
      dropdownMenu: [],
    },
    {
      link: '/account',
      label: 'ACCOUNT',
      dropdownMenu: [],
    },
    {
      link: '#',
      label: 'GIFTS',
      dropdownMenu: [],
    },
    {
      link: '#',
      label: 'IMPORT',
      dropdownMenu: [],
    },
    {
      link: '#',
      label: 'COMMUNITY',
      dropdownMenu: [],
    },
    {
      link: '#',
      label: 'TELEGRAM',
      dropdownMenu: [],
    },
  ]
  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])
  const subLinks = activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items
  const homeLink = menuItems.find((link) => link.label === 'Home')
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)
  const linkActive = window.location.href
  const local = window.location.origin
  const checkActive = (e, r) => {
    const linkActive = window.location.href
    localStorage.setItem('index', r)
    if (linkActive.indexOf(e) !== -1) {
      setClassActive('active')
      setIndexActive(r)
      setisActive(0)
    } else {
      setClassActive('')
    }
  }

  const checkIsActive = (index) => {
    setisActive(index)
    localStorage.setItem('indexDropdown', index)
  }
  const checkHome = () => {
    if (linkActive.slice(-1) === '/') {
      setClassActive('')
      setIndexActive(0)
      localStorage.setItem('index', '0')
      localStorage.setItem('indexDropdown', '0')
    }
  }
  const checkLink = () => {
    for (let i = 1; i < data.length; i + 1) {
      const { link } = data[i]
      if (linkActive.indexOf(link) !== -1) {
        setClassActive('active')
        setIndexActive(i)
        localStorage.setItem('index', i.toString())
      }
      i++
    }
  }
  useEffect(() => {
    checkHome()
    checkLink()
    if (localStorage.getItem('index') !== '0') {
      setIndexActive(Number(localStorage.getItem('index')))
      // indexDropdown.current = Number(localStorage.getItem('indexDropdown'))
      setisActive(Number(localStorage.getItem('indexDropdown')))
      setClassActive('active')
    } else if (localStorage.getItem('index') === '0') {
      setClassActive('')
      setIndexActive(0)
    }
  }, [linkActive, classActive])
  const handleClick = (label) => {
    if (label === 'ABOUT THE GAME') {
      window.location.replace('https://docs.darkknight.games/')
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  const [showMenuMB, setShowMenuMB] = useState('none')

  return (
    <Wrapper>
      <Container>
        <Router>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            <div
              style={{ order: window.screen.width <= 1024 ? '2' : '' }}
              onClick={() => localStorage.setItem('index', '0')}
            >
              <Logo isDark={isDark} href={homeLink?.href ?? '/'} />
            </div>
            <div
              style={{
                display: window.screen.width <= 1024 ? 'inline-block' : 'flex',
                alignItems: 'center',
                gap: '30px',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {/* <Stack
                style={{
                  display: window.screen.width <= 1024 ? 'flex' : 'none',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Stack
                  style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', marginLeft: window.screen.width <= 1024 && window.screen.width > 768 ? '20px' : '' }}
                >
                  <img width={ window.screen.width <= 425 ? '41px' :window.screen.width <= 1024 ? '82px' : '' } src={images.dropDownButton} alt="" />
                  <p
                    style={{
                      color: 'white',
                      fontSize: window.screen.width <= 425 ?  '18px' :window.screen.width <= 1024 ?'24px' :'',
                      lineHeight: window.screen.width <= 425 ?  '18px' :window.screen.width <= 1024 ?'24px' :'',
                      fontWeight: '700',
                    }}
                  >
                    CRYPTO MANHUA
                  </p>
                </Stack>
                <img
                  width={ window.screen.width <= 425 ?  '30px' : window.screen.width <= 1024 ? '60px' : ''}
                  style={{ marginRight: window.screen.width <= 1024 && window.screen.width > 768 ? '30px' : '23px' }}
                  src={images.homeMobile}
                  alt=""
                />
              </Stack> */}

              <StyledListItem style={{ display: window.screen.width <= 1024 ? 'none' : 'flex' }}>
                {data.map((items, index) => (
                  <div key={index}>
                    <Link to={items.link || '#'} style={{ display: index === 0 ? 'none' : 'flex' }}>
                      <StyledMenuItem
                        style={{ display: 'flex' }}
                        className={index === indexActive ? classActive : ''}
                        onClick={() => checkActive(items.link, index)}
                      >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          <Link
                            to={items.link}
                            style={{
                              display: index === 0 ? 'none' : 'flex',
                              gap: '10px',
                            }}
                          >
                            {/* <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {items.icon}
                            </div> */}
                            <TextNav
                              onClick={handleLabelClick}
                              fontSize="14px"
                              fontWeight="600"
                              fontStyle="normal"
                              className={index === indexActive ? classActive : ''}
                            >
                              {items.label}
                            </TextNav>
                          </Link>
                          {items.dropdownMenu.length > 0 ? (
                            <DropdownMenu className="dropdown">
                              {items.dropdownMenu.map((i, k) => (
                                <Link key={k} style={{ width: '100%' }} to={i.link}>
                                  <StyledDropdownMenu onClick={() => checkIsActive(k)}>
                                    <Link onClick={() => handleClick(i.label)} style={{ padding: '10px' }} to={i.link}>
                                      {i.label}
                                    </Link>
                                  </StyledDropdownMenu>
                                </Link>
                              ))}
                            </DropdownMenu>
                          ) : null}
                        </div>
                      </StyledMenuItem>
                    </Link>
                  </div>
                ))}
              </StyledListItem>
              <Stack style={{ display: window.screen.width <= 1024 ? 'flex' : 'none' }}>
                <div
                  onClick={() => {
                    setShowMenuMB('inline-block')
                  }}
                >
                  <img
                    src={images.dropDownButton}
                    style={{
                      width: window.screen.width <= 575 ? '24px' : '48px',
                      height: window.screen.width <= 575 ? '24px' : '48px',
                    }}
                  />
                </div>
              </Stack>
            </div>

            <UserMenuV2 data={data} />
          </FixedContainer>

          <Stack
            display={showMenuMB}
            style={{
              background: '#2D3245',
              width: '100vw',
              height: '100vh',
              zIndex: '999',
              position: 'absolute',
              padding: '39px 30px',
            }}
          >
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={images.avtB1} style={{ width: '41px', height: '41px', marginRight: '10px' }} />
                <p
                  style={{
                    height: '18px',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 'bold',
                    backgroundImage: 'linear-gradient(180deg, #C79676 0%, #EE965C 100%)',
                    WebkitBackgroundClip: 'text',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}
                >
                  CRYPTO MANHUA
                </p>
              </div>
              <button
                type="button"
                onClick={() => setShowMenuMB('none')}
                style={{
                  width: '21px',
                  height: '21px',
                  backgroundImage: 'linear-gradient(180deg, #C79676 0%, #EE965C 100%)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
              >
                X
              </button>
            </div>
            <div style={{ position: 'relative', width: '100%' }}>
              <hr
                style={{
                  border: 'none',
                  width: '100%',
                  height: '1px',
                  background: 'linear-gradient(180deg, rgba(199, 150, 118, 0.09) 0%, rgba(238, 150, 92, 0.09) 100%)',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                }}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '10px' }}>
              {data.map((item, index) => {
                return (
                  <>
                    <p
                      style={{
                        fontSize: '26px',
                        lineHeight: '41.63px',
                        fontWeight: '500',
                        color: '#FFFFFF',
                        marginBottom: '32px',
                      }}
                    >
                      {item.label}
                    </p>
                  </>
                )
              })}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={images.playNow} width={212} />
              </div>
            </div>
          </Stack>

          {data[indexActive].dropdownMenu.length > 0 && (
            <NavDropdownMenu>
              {data[indexActive].dropdownMenu.map((item, index) => (
                <div key={index}>
                  <Link to={item.link}>
                    <StyledItemNav className={index === isActive ? 'active' : ''} onClick={() => checkIsActive(index)}>
                      <Link to={item.link}>{item.label}</Link>
                      {item.img !== '' ? <img src={item.img} /> : null}
                    </StyledItemNav>
                  </Link>
                </div>
              ))}
            </NavDropdownMenu>
          )}

          <NewNav />
          {/* 
          {isMobile && (
            <BottomNavV2
              data={data}
              items={menuItems}
              activeItem={activeMenuItem?.href}
              activeSubItem={activeSubMenuItem?.href}
            />
          )}
          {isTablet && (
            <BottomNav items={menuItems} activeItem={activeMenuItem?.href} activeSubItem={activeSubMenuItem?.href} />
            // <BottomNavV2 data={data} />
          )} */}
        </Router>
      </Container>
      {/* <div style={{ position: 'fixed', top: '10%', left: isMobile ? '20%' : isTablet ? '55%' : '70%', zIndex: 1000 }}>
        <CountDown />
      </div> */}
      <BodyWrapper mt={!subLinks ? '0' : '0'}>
        {/* <CurrencyExchange /> */}
        {/* <Inner isPushed={false} showMenu={showMenu}>
          <NewFooter />
          <Footer
            items={getFooterLinks}
            isDark={isDark}
            toggleTheme={toggleTheme}
            langs={languageList}
            setLang={setLanguage}
            currentLang={currentLanguage.code}
            cakePriceUsd={Number(cakePriceUsd)}
            buyCakeLabel={t('Buy CAKE')}
            mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0px']}
          />
        </Inner> */}
      </BodyWrapper>
    </Wrapper>
  )
}

export default MenuV2
