import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'
import modal from 'antd/lib/modal'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Helvetica';
    src: url("/assets/fonts/Helvetica.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: 'Helvetica Light';
    src: url("/assets/fonts/Helvetica-Light.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: 'Helvetica';
    src: url("/assets/fonts/helvetica-neue-medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: Helvetica, sans-serif;
    src: url("/assets/fonts/Helvetica-BoldOblique.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: 'Helvetica Rounded';
    src: url("/assets/fonts/HelveticaRoundedLTStd-BdCn.otf");
    font-weight: 400;
  }
  @font-face {
    font-family: 'Helvetica Rounded';
    src: url("/assets/fonts/HelveticaRoundedLTStd-Bd.otf");
    font-weight: 600;
  }
  @font-face {
    font-family: 'Helvetica Rounded';
    src: url("/assets/fonts/HelveticaRoundedLTStd-Black.otf");
    font-weight: 700;
  }
  @font-face {
    font-family: 'Helvetica Compressed';
    src: url("/assets/fonts/HelveticaCompressed.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: Ethnocentric;
    font-weight: 400;
    src: url("/assets/fonts/Ethnocentric.otf");
  }
  @font-face {
    font-family: Ethnocentric;
    font-weight: 700;
    src: url("/assets/fonts/Ethnocentric.otf");
  }
  @font-face {
    font-family: Poppins;
    font-weight: 400;
    src: url("/assets/fonts/Poppins-Regular.tff");
  }
  @font-face {
    font-family: Poppins;
    font-weight: 700;
    src: url("/assets/fonts/Poppins-Regular.tff");
  }

@font-face {
  font-family: "Anek Bangla";
  src: url("/assets/fonts/AnekBangla-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Anek Bangla";
  src: url("/assets/fonts/AnekBangla-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "DM Sans";
      src: url("/assets/fonts/DMSans-Medium.woff");

  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Medium.woff2") format("woff2"),
    url("Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Russo One";
  src: url("/assets/fonts/RussoOne-Regular.woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Russo One2";
  src: url("/assets/fonts/RussoOne-Regular.woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hind';
  src: url("/assets/fonts/Hind-Semibold.tff");
  font-weight: 600;
}

@font-face {
  font-family: 'Hind';
  src: url("/assets/fonts/Hind-Regular.tff");
  font-weight: 400;
}
  body {
    /* background-color: #13171b; */
    background-color: #FFF;
    height: auto;
  }

}
`

export default GlobalStyle
