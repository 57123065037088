import { Button, ButtonProps, Text } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'
import useResponsive from 'hooks/useResponsive'

const StyledButton = styled.a`
  width: 212px;
  height: 100px;
  display: flex;
  margin-right: 240px;
  margin-top: 20px;
  padding: 25px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/crypto/connecting.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 215px;
  color: #00f0ff;
  text-shadow: 0px 2px 0px rgba(255, 255, 255, 0.25);
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
  @media (max-width: 1024px) {
    width: 215px;
    height: 100px;
    padding: 10px 15px;
    margin-right: 0px;
    margin-top: 10px;
    order: 2;
  }

  @media (max-width: 576px) {
    width: 72px;
    height: 34px;
    min-width: 72px;
    padding: 10px 15px;
    margin-right: 0px;
    margin-top: 10px;
    order: 2;
  }
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }
  const { isSmallDevice, isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <StyledButton onClick={handleClick} {...props}>
      <Text
        style={{
          width: window.screen.width <= 575 ? '43px' : '163px',
          height: window.screen.width <= 575 ? '12px' : '32px',
          marginBottom: window.screen.width <= 575 ? '10px' : '22px',
          marginRight: window.screen.width <= 575 ? '8px' : '',
          fontSize: window.screen.width <= 575 ? '10px' : '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          color: '#DA0C0C',
          lineHeight: window.screen.width <= 575 ? '16.01px' : '32px',
        }}
      >
        {window.screen.width <= 575 ? 'CONNECT' : 'CONNECT WALLET'}
      </Text>
      {/* {children || <Trans>Connect Wallet</Trans>} */}
    </StyledButton>
  )
}

export default ConnectWalletButton
