import { rinkeby, mainnet, goerli, optimism } from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche_fuji',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.ftm.tools',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.testnet.fantom.network',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}

const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }

export const bsc: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    public: 'https://bsc-dataseed1.binance.org/',
    default: 'https://bsc-dataseed1.binance.org/',
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 15921452,
  },
}

export const bscTest: Chain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    public: 'https://bsc-testnet.publicnode.com',
    default: 'https://bsc-testnet.publicnode.com',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 17422483,
  },
  testnet: true,
}

export const matic: Chain = {
  id: 137,
  name: 'Matic Mainnet',
  network: 'matic-mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'Matic',
  },
  rpcUrls: {
    public: 'https://polygon-rpc.com',
    default: 'https://polygon-rpc.com',
  },
  blockExplorers: {
    default: { name: 'PolygonScan', url: 'https://polygonscan.com/' },
  },
  multicall: {
    address: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d',
    blockCreated: 14000345,
  },
  testnet: true,
}

export const CHZ: Chain = {
  id: 88888,
  name: 'Chiliz Chain - Mainnet',
  network: 'Chiliz Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'Chiliz',
    symbol: 'CHZ',
  },
  rpcUrls: {
    public: 'https://chiliz.publicnode.com',
    default: 'https://chiliz.publicnode.com',
  },
  blockExplorers: {
    default: { name: 'ChilScan', url: 'https://chiliscan.com' },
  },
  // multicall: {
  //   address: '0x3506424F91fD33084466F402d5D97f05F8e3b4AF',
  //   blockCreated: 14000345,
  // },
  testnet: true,
}

export const core: Chain = {
  id: 1116,
  name: 'Core Blockchain Mainnet',
  network: 'CORE',
  nativeCurrency: {
    decimals: 18,
    name: 'Core Chain MainNet',
    symbol: 'CORE',
  },
  rpcUrls: {
    public: 'https://rpc.coredao.org/',
    default: 'https://rpc.coredao.org/',
  },
  blockExplorers: {
    default: {
      name: 'CoreScan',
      url: 'https://scan.coredao.org',
    },
  },
}
export const coreTEST: Chain = {
  id: 1115,
  name: 'Core Chain TestNet',
  network: 'bsc-testnet',
  rpcUrls: {
    public: 'https://rpc.test.btcs.network',
    default: 'https://rpc.test.btcs.network',
  },
  blockExplorers: {
    default: {
      name: 'CoreScanTEST',
      url: 'https://scan.test.btcs.network/',
    },
  },
}
export { rinkeby, mainnet, goerli, optimism }
